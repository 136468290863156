import DamilarePhoto from "../assets/images/team/damilare-mh-team.jpeg";
import AdemolaPhoto from "../assets/images/team/ceo-ademola.jpeg";
import RukemePhoto from "../assets/images/team/rukeme-mh-team.jpeg";
import GideonPhoto from "../assets/images/team/gideon-mh-team.jpeg";
import BenedictaPhoto from "../assets/images/team/benedicta-mh-team.jpeg";
import PaulPhoto from "../assets/images/team/paul-mh-team.jpeg";
import MaryannPhoto from "../assets/images/team/maryann-mh-team.jpeg";

export const teams = [
  {
    photo: AdemolaPhoto,
    name: "Ademola Adeosun",
    title: "Managing Director / CEO",
    details:
    `Ademola Adeosun is the Founder and CEO of Mosun Homes and Realtors Limited. With over 7 years experience in the real estate sector, Ademola sits on the executive board of the firm over-seeing business model and growth.Over the years, Ademola has acquired deep knowledge and excellent understanding of various aspects of the real estate business in a variety of roles and responsibilities, from structing investment funds vehicles for the purpose of developing and investing in valuable real estate assets, creating value and returns for limited partners. Ademola brings integrity, a sound business head and a wealth of invaluable leadership experience and capabilities to the Nigerian Real Estate Industry. In his role as the CEO/Managing Director of Mosun Homes and Realtors, he leads the company's drive towards the attainment of a sustainable successful integrated real estate business including Environmental, Social and Governance initiatives and programs. `,
  },

  {
    photo: DamilarePhoto,
    name: "Dare Oludare",
    title: "Chief Operating Officer",
    details:
      `Dare is a real estate business strategy and project management professional with over five (5) years experience in delivering key infrastructure and digital innovation projects. He has extensive advisory experience in providing strategic and commercial advice on real estate investments in Nigeria. `,
  },

  {
    photo: BenedictaPhoto,
    name: "Benedicta Bassey",
    title: "HR / Admin Officer",
    details:
      `Benedicta is a Human/ Administrative officer with over 3 years of experience. She is also an experienced project administrator. Her key competencies are office Administration, employee relation, recruitment and customer relations. Her desire for personnel development and job satisfaction has been a key drive through her career.`,
  },

  {
    photo: RukemePhoto,
    name: "Rukeme Taire",
    title: "Real Estate Sales Agent",
    details:
      `Rukeme is a real estate sales expert with over 3 years of experience in the real estate industry. She is highly skilled in property negotiations and assets, customer orientation on assets, Real estate business assessment and management. Kindly get in touch for your property acquisitions at strategic locations. `,
  },

  {
    photo: GideonPhoto,
    name: "Gideon Philip",
    title: "Senior Accountant",
    details:
      `Gideon is an experienced Accounting and Finance professional with over 5 years of cognate experience in budgeting and budgetary control, financial reporting, financial analysis, financial strategy and tax management across different industries. He is adept in the installation of internal control and suitable accounting systems. `,
  },

  {
    photo: PaulPhoto,
    name: "Paul Olagunju",
    title: "Facility Manager",
    details:
      `Paul is an experience Real Estate professional with over six (6) years experience in facility management, letting, sales, feasibility/viability appraisal and Valuation.`,
  }
];
